@use '../colors.scss';
$cornerSize: 60px;
$sideMargin: 50px;
.pentagon {
    position: relative;
    clip-path: polygon(0% 0%, calc(100% - $sideMargin) 0%, 
                       calc(100% - $sideMargin) calc(100% - $cornerSize), 
                       calc(100% - $sideMargin - $cornerSize) 100%, 
                       0% 100%);
    background-color: colors.$light-gray;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 450px;
    color: colors.$orange-color;
    text-transform: uppercase;
    align-items: flex-start;

    > .container {
        pointer-events: none;
    }
    h1 {
        display: flex;
        align-items: center;
        span {
            margin-left: 10px;
            font-weight: 500;
            font-size: 100px;
        }
    }
    img {
        height: 200px;
        margin-bottom: 100px;
    }
    .am-slider {
        z-index: -5;

        .am-slider-nav {
            padding-right: 10%;
            margin-bottom: 30px;
        }
    }
}


h2 {
    color: colors.$orange-color;
    font-weight: bold;
    font-size: 40px !important;
}

.studio-container {
    padding-top: 50px;
    padding-bottom: 50px;
}

.gear-list {
    list-style-type: none;
    margin-top: 20px;
    font-weight: 500;
}

.sec.sec-hero {
    background-image: url('./cymbals.JPEG');
    
}

.studio-container div {
    font-size: 20px;
    margin-bottom: 40px;
}