@use '../colors.scss';

.am-slider-inner {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}
  
.am-slider {
    position: relative;
    -moz-scrollbars-none: none;
    -ms-overflow-style: none;
    
    ::-webkit-scrollbar {
        display: none;
    }
}

.am-slider.slider-full {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .am-slider-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        align-items: flex-start;
    }
}
.am-slider-nav {
    display: flex;
    position: absolute;
    bottom: 0px; 
    width: 90%;
    height: 7px;
    .am-slider-bar {
        flex: 1;
        height: 7px;
        background: rgba(colors.$light-gray, 0.55);
        margin-right: 10px;
        box-shadow: rgba(#000, 0.75) 3px 7px 5px;
        max-width: 140px;
    }

    .am-slider-bar.active {
        background: rgba(colors.$light-gray, 1);
    }
}
.am-slider .am-slider-prev,.am-slider .am-slider-next {
    position: absolute;
    width: 40px;
    height: 40px;
    transition: opacity 0.1s;
    opacity: 0;
}
.am-slider .am-slider-prev:not(.active),.am-slider .am-slider-next:not(.active) {
    display: none;
    pointer-events: none;
}
.am-slider .am-slider-prev.active, .am-slider .am-slider-next.active {
    opacity: 1;
    pointer-events: auto;
}
.am-slider .am-slider-prev {
    top: 50%;
    left: 30px;
    transform: rotate(90deg) translateY(-50%);
    transform-origin: top left;
    content: url(/assets/home/DOWN-ARROW.svg);
}
.am-slider .am-slider-next {
    right: 30px;
    top: 50%;
    transform: rotate(-90deg) translateY(-50%);
    transform-origin: top right;
    content: url(/assets/home/DOWN-ARROW.svg);
}
.am-slider-snap-to {
    user-select: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    flex: none;
}

.am-slider-snap-to:active {
    opacity: 0.8;
}

.no-overflow {
    overflow: hidden;
}